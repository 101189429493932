<template lang="html">
  <v-app>
    <!-- <v-main :style="{backgroundImage:'url('+ require('@/assets/web3.jpg')+')',backgroundSize:'cover'}"> -->
    <v-main :style="{backgroundImage:'url('+ require('@/assets/pemkot2.jpeg')+')',backgroundSize:'cover'}">
      <v-container fluid class="fill-height" style="backdrop-filter:blur(7px)">
        <v-col cols="12" align="center" justify="center" style="background:rgba(51,51,51,0.5)
        ">
          <v-avatar size="200" width="133" class="mb-5">
            <v-img :src="avatarPhoto" @error="replacePhoto(pegawai.foto)" class="grey lighten-2">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <div class="flex-column d-flex mb-7">
            <span class="headline white--text">{{pegawai.nama}}</span>
            <span class="body-1 white--text">{{pegawai.nip}}</span>
            <span class="body-1 white--text">{{pegawai['perangkat daerah']}}</span>
            <span class="body-1 white--text">{{pegawai['jabatan_penyetaraan'] != 'null' ? pegawai['jabatan_penyetaraan'] : pegawai['jabatan']}}</span>
            <!-- <span v-if="pegawai['jabatan'] != 'null' && pegawai['jabatan'] != ''" class="body-1 white--text"><b>Jabatan:</b> {{pegawai['jabatan']}}</span> -->
            <!-- <span v-if="pegawai['jabatan_penyetaraan'] != 'null' && pegawai['jabatan_penyetaraan'] != ''" class="body-1 white--text"><b>Jabatan Penyetaraan:</b> {{pegawai['jabatan_penyetaraan']}}</span> -->
          </div>
          <div>
            <v-btn v-for="(role, index) in roles" class="mr-7" :key="index" color="primary" large @click="idx=index;changeCurrentRole(role)" :loading="loading && index == idx" :disabled="loading">{{role == 'ketua_tim_pokja' ? 'Ketua Tim Pokja' : role == 'kepala_sekolah' ? 'Kepala Sekolah' : role == 'koordinator_kesehatan' ? 'Koordinator Kesehatan' : role}}</v-btn>
          </div>
        </v-col>
      </v-container>
    </v-main>
    <v-btn
      @click="logout()"
      class="ma-5"
      color="primary"
      dark
      absolute
      right
      title="keluar"
    >
      LOGOUT &nbsp;<v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {local} from '@/store/local'
export default {
  data(){
    return{
      avatarPhoto : '',
      pegawai:{},
      roles:[],
      idx:-1,
      loading:false
    }
  },
  created(){
    let check = store.state.user
    this.roles = check.current.roles
    this.loading = true
    let ga_ = local.getLocal("login_via_ga")
    if(ga_){
      let roles_ = local.getLocal("_r")
      this.roles = roles_
    }else{
      if(!this.roles){
        this.roles = store.state.user.data.roles
        check.direct = false
      }
    }
    if(check.direct == false){
      store.dispatch('user/getDataPegawai').then((pegawai)=>{
        if (pegawai.foto.match('undefined')){
          this.avatarPhoto = process.env.VUE_APP_IMG_URL + pegawai.nip +".jpeg"
        } else {
          this.avatarPhoto = pegawai.foto.replace(/\.jpg$/gi,'.jpeg')
        }
        this.pegawai = pegawai
        store.commit('user/SET_AVATAR', this.avatarPhoto)
        this.loading = false
      })
      this.roles = store.state.user.data.roles
      this.$store.getters["user/data"].current_role = this.roles[0]
    }else{
      console.log('Direct from SSO and choose role is success!')
      // this.roles = check.current.roles
      this.avatarPhoto = check.dataPegawai.foto
      this.pegawai = check.dataPegawai
      // check.direct = false
      this.loading = false
      this.$store.getters["user/data"].current_role = this.roles[0]
    }
    if(this.roles.length === 1){
      this.changeCurrentRole(this.roles[0])
    }
  },
  methods:{
    replacePhoto(url){
      this.avatarPhoto = url
    },
    changeCurrentRole(role){
      this.$store.getters["user/data"].jenis_pbj = role
      this.loading=true
      let check = store.state.user
      console.log("check state user switch role: ", check)
      // if(!check.current.role.includes("PLT")){
      let ga_ = local.getLocal("login_via_ga")
      if(role.includes('PLT') || role.includes('PLH')){
        var plt_name = local.getLocal("plt_names")
        var plt_id = local.getLocal("plt_ids")
        var temp = []
        plt_name.forEach(el => {
          var data = el.replace(/\s/g, '')
          temp.push(data)
        });
        var plt_role = role.slice(4).replace(/\s/g, '')
        var i = temp.indexOf(plt_role, null)
        var id = plt_id[i]
        local.clearItem("plt_id_")
        local.setLocal("plt_id_", id)
        // local.setLocal("plt_id_", check.dataPegawai.nip)
      }else{
        if(!ga_){
          if(check.dataPegawai.nip.length == 18){
            local.setLocal("nip", check.dataPegawai.nip)
            local.setLocal("nip_token", check.data.token)
            // local.setLocal("nip", check.data.id)
          }
        }else{
          if(check.dataPegawai.nip.length == 18){
            local.setLocal("nip", check.dataPegawai.nip)
            local.setLocal("nip_token", check.data.token)
            // local.setLocal("nip", check.data.id)
          }
          // if(check.current.username.length == 18){
          //   local.setLocal("nip", check.current.username)
          //   local.setLocal("nip_token", check.data.token)
          // }
        }
      }
      store.commit('routes/REFRESH')
      store.commit('user/SET_ROLE', role)
      store.commit('user/SET_TOKEN', check.data.token)
      router.push('/loading')
    },
    logout(){
      store.commit('user/SET_DIRECT', false)
      store.dispatch('user/logout')
    }
  }
}
</script>

<style lang="css" scoped>
</style>
